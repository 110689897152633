.text {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff; /* Keep the background white */
}

.big-text {
  font-size: 6em;
  font-weight: bold;
  color: #e0e0e0; /* Slightly darker white for big text */
  text-transform: uppercase;
  letter-spacing: 10px;
}

.subtext {
  margin-top: 20px;
  font-size: 1.5em;
  color: #e0e0e0; /* Slightly darker white for subtext */
}
